<template>
  <mobile-screen :header="true" screen-class="gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpOnlineLinkName,
          params: $route.params
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName, params: $route.params }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global", "maps", "select-map") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="mapList && mapList.length">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input id="mapStatus" type="checkbox" v-model="unsetMapValue" />
                <label for="mapStatus"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("level", "edit-level", "no-map-assigned") }}
            </dd>
          </dl>
        </div>
      </li>
      <li
        class="clebex-item-section-item"
        v-for="(map, idx) in mapList"
        :key="map.id"
      >
        <div class="clebex-item-content-wrapper">
          <div class="checkbox multi block">
            <input
              type="radio"
              :id="`map${idx}`"
              name="map_id"
              :checked="selectedMapId === map.id"
              @change="selectMapAndReturn(map)"
            />
            <label :for="`map${idx}`">
              <icon icon="#cx-app1-checkmark" />
              {{ map.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "MapsSelection",
  mixins: [helpOnlineMixin],
  data() {
    return {
      selecting: false,
      searchQuery: "",
      notDefined: false,
      helpSlug: "levels-5d-maps"
    };
  },
  created() {
    this.getMaps({
      includes: ["levels"]
    });
  },
  computed: {
    ...mapState("level", [
      "maps",
      "selectedMap",
      "selectedLevel",
      "unsetMapValue"
    ]),
    selectedMapId() {
      if (this.selectedMap && !this.unsetMapValue) {
        return this.selectedMap.id;
      } else if (
        this.selectedLevel &&
        this.selectedLevel.length &&
        !this.unsetMapValue
      ) {
        return this.selectedLevel[0].map_id;
      }
      return null;
    },
    unsetMapValue: {
      get() {
        return this.$store.state.level.unsetMapValue;
      },
      set(val) {
        this.selectMap(null);
        this.unsetMap(val);
        this.$router.push({
          name: this.backLinkName,
          params: this.$route.params
        });
      }
    },
    mapList() {
      const { maps } = this;
      if (maps && maps.data && maps.data.length) {
        return maps.data.filter(
          item => item.id === this.selectedMapId || !item.level
        );
      }
      return [];
    }
  },
  methods: {
    ...mapActions("level", ["getMaps", "selectMap", "unsetMap"]),
    selectMapAndReturn(map) {
      this.selectMap(map);
      this.unsetMap(false);
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    helpOnlineLinkName: {
      type: String
    }
  }
};
</script>
